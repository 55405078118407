// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("date-fns");
var ViewStack = require("../../../reason/common/ViewStack.bs.js");
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Text__Jsx3 = require("../../../uikit/reason/helpers/Text__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CalendarDate = require("../../../reason/common/types/CalendarDate.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Colors = require("uikit/styles/colors");
var FragmentErrorBoundary = require("../../common/ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UnselectedUnionMemberError = require("../../common/ErrorHandling/UnselectedUnionMemberError.bs.js");
var ClientPropertyMlsStatus_mlsPlan_graphql = require("../../../__generated__/ClientPropertyMlsStatus_mlsPlan_graphql.bs.js");
var ClientPropertyMlsStatus_property_graphql = require("../../../__generated__/ClientPropertyMlsStatus_property_graphql.bs.js");

var convertFragment = ClientPropertyMlsStatus_mlsPlan_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ClientPropertyMlsStatus_mlsPlan_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClientPropertyMlsStatus_mlsPlan_graphql.node, convertFragment);
}

var MlsPlanFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = ClientPropertyMlsStatus_property_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(ClientPropertyMlsStatus_property_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClientPropertyMlsStatus_property_graphql.node, convertFragment$1);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function formatCalendarDate(calendarDate) {
  return DateFns.format(CalendarDate.toLocalDate(calendarDate), "MMMM d");
}

function ClientPropertyMlsStatus$MlsStatus(props) {
  var detailText = props.detailText;
  return JsxRuntime.jsxs(ViewStack.make, {
              space: "sm",
              alignItems: "center",
              flexDirection: "row",
              children: [
                JsxRuntime.jsx(Icon__Jsx3.make, {
                      icon: "Calendar",
                      size: 20
                    }),
                JsxRuntime.jsxs(Text__Jsx3.make, {
                      as_: "div",
                      fontSize: {
                        NAME: "px",
                        VAL: 16
                      },
                      color: Colors.tuatara,
                      children: [
                        JsxRuntime.jsx(Text__Jsx3.make, {
                              fontWeight: {
                                NAME: "n",
                                VAL: 500
                              },
                              children: props.status
                            }),
                        detailText !== undefined ? JsxRuntime.jsx(Text__Jsx3.make, {
                                color: Colors.gray,
                                children: " " + detailText
                              }) : null
                      ]
                    })
              ]
            });
}

var MlsStatus = {
  make: ClientPropertyMlsStatus$MlsStatus
};

function ClientPropertyMlsStatus$FromMlsPlan(props) {
  var activeOnMlsSince = props.activeOnMlsSince;
  var mlsPlan = RescriptRelay_Fragment.useFragment(ClientPropertyMlsStatus_mlsPlan_graphql.node, convertFragment, props.mlsPlan);
  var onMlsAt;
  if (activeOnMlsSince !== undefined) {
    return JsxRuntime.jsx(ClientPropertyMlsStatus$MlsStatus, {
                status: "On-MLS",
                detailText: "since " + DateFns.format(CalendarDate.toLocalDate(Caml_option.valFromOption(activeOnMlsSince)), "MMMM d")
              });
  }
  var variant = mlsPlan.NAME;
  if (variant === "AlreadyOnMls") {
    return null;
  }
  if (variant === "ListOnMlsAsActive") {
    onMlsAt = mlsPlan.VAL.onMlsAt;
  } else {
    if (variant === "NoMlsPlan") {
      return JsxRuntime.jsx(ClientPropertyMlsStatus$MlsStatus, {
                  status: "Off-MLS"
                });
    }
    if (variant === "UnselectedUnionMember") {
      return UnselectedUnionMemberError.raise("Unselected mls status " + mlsPlan.VAL);
    }
    onMlsAt = mlsPlan.VAL.activeOnMlsOn;
  }
  return JsxRuntime.jsx(ClientPropertyMlsStatus$MlsStatus, {
              status: "Off-MLS",
              detailText: "until " + DateFns.format(CalendarDate.toLocalDate(onMlsAt), "MMMM d")
            });
}

var FromMlsPlan = {
  make: ClientPropertyMlsStatus$FromMlsPlan
};

function ClientPropertyMlsStatus$WithoutErrorBoundary(props) {
  var property = RescriptRelay_Fragment.useFragment(ClientPropertyMlsStatus_property_graphql.node, convertFragment$1, props.property);
  return JsxRuntime.jsx(ClientPropertyMlsStatus$FromMlsPlan, {
              mlsPlan: property.mlsPlan.fragmentRefs,
              activeOnMlsSince: property.activeOnMlsSince
            });
}

var WithoutErrorBoundary = {
  make: ClientPropertyMlsStatus$WithoutErrorBoundary
};

function ClientPropertyMlsStatus(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "MLS status",
              children: JsxRuntime.jsx(ClientPropertyMlsStatus$WithoutErrorBoundary, {
                    property: props.property
                  })
            });
}

var make = ClientPropertyMlsStatus;

exports.MlsPlanFragment = MlsPlanFragment;
exports.PropertyFragment = PropertyFragment;
exports.formatCalendarDate = formatCalendarDate;
exports.MlsStatus = MlsStatus;
exports.FromMlsPlan = FromMlsPlan;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* date-fns Not a pure module */
