// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var HtmlHolder = require("../../reason/common/HtmlHolder.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AvatarSrcUrl = require("../../utils/AvatarSrcUrl.bs.js");
var MarkupHelpers = require("../../reason/common/MarkupHelpers/MarkupHelpers.bs.js");
var AgentInfo__Jsx3 = require("../../uikit/reason/atoms/AgentInfo/AgentInfo__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../common/ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var SharedPropertyMessage_sharedProperty_graphql = require("../../__generated__/SharedPropertyMessage_sharedProperty_graphql.bs.js");
var SharedPropertyMessage_AgentInfo_agent_graphql = require("../../__generated__/SharedPropertyMessage_AgentInfo_agent_graphql.bs.js");

var convertFragment = SharedPropertyMessage_AgentInfo_agent_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SharedPropertyMessage_AgentInfo_agent_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SharedPropertyMessage_AgentInfo_agent_graphql.node, convertFragment);
}

var Fragment_membershipStatusV5_decode = SharedPropertyMessage_AgentInfo_agent_graphql.Utils.membershipStatusV5_decode;

var Fragment_membershipStatusV5_fromString = SharedPropertyMessage_AgentInfo_agent_graphql.Utils.membershipStatusV5_fromString;

var Fragment = {
  membershipStatusV5_decode: Fragment_membershipStatusV5_decode,
  membershipStatusV5_fromString: Fragment_membershipStatusV5_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function SharedPropertyMessage$AgentInfo$WithoutErrorBoundary(props) {
  var agent = RescriptRelay_Fragment.useFragment(SharedPropertyMessage_AgentInfo_agent_graphql.node, convertFragment, props.sharedProperty);
  var profilePhoto = agent.profilePhoto;
  var avatar = profilePhoto !== undefined ? profilePhoto.url : AvatarSrcUrl.place_holder;
  return JsxRuntime.jsx(AgentInfo__Jsx3.make, {
              avatar: avatar,
              brokerage: agent.brokerage,
              size: "small",
              name: agent.displayName
            });
}

var WithoutErrorBoundary = {
  make: SharedPropertyMessage$AgentInfo$WithoutErrorBoundary
};

function SharedPropertyMessage$AgentInfo(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "Sharing agent info",
              children: JsxRuntime.jsx(SharedPropertyMessage$AgentInfo$WithoutErrorBoundary, {
                    sharedProperty: props.sharedProperty
                  })
            });
}

var AgentInfo = {
  Fragment: Fragment,
  WithoutErrorBoundary: WithoutErrorBoundary,
  make: SharedPropertyMessage$AgentInfo
};

var convertFragment$1 = SharedPropertyMessage_sharedProperty_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(SharedPropertyMessage_sharedProperty_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SharedPropertyMessage_sharedProperty_graphql.node, convertFragment$1);
}

var Fragment$1 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function SharedPropertyMessage(props) {
  var match = RescriptRelay_Fragment.useFragment(SharedPropertyMessage_sharedProperty_graphql.node, convertFragment$1, props.sharedProperty);
  var message = match.message;
  return JsxRuntime.jsx(MarkupHelpers.Container.make, {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("h2", {
                            children: match.subject,
                            className: "text-gray-700 font-medium text-3xl"
                          }),
                      message !== undefined ? JsxRuntime.jsx(HtmlHolder.make, {
                              innerHtml: message
                            }) : null,
                      JsxRuntime.jsx(SharedPropertyMessage$AgentInfo, {
                            sharedProperty: match.sender.fragmentRefs
                          })
                    ],
                    className: "bg-white p-5 flex flex-col gap-6 border rounded"
                  })
            });
}

var make = SharedPropertyMessage;

exports.AgentInfo = AgentInfo;
exports.Fragment = Fragment$1;
exports.make = make;
/* HtmlHolder Not a pure module */
