// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

function unwrap_fragment_status(u) {
  var v = u.__typename;
  switch (v) {
    case "ActiveProperty" :
        return {
                NAME: "ActiveProperty",
                VAL: u
              };
    case "ExpiredProperty" :
        return {
                NAME: "ExpiredProperty",
                VAL: u
              };
    case "SoldProperty" :
        return {
                NAME: "SoldProperty",
                VAL: u
              };
    case "UnderContractProperty" :
        return {
                NAME: "UnderContractProperty",
                VAL: u
              };
    default:
      return {
              NAME: "UnselectedUnionMember",
              VAL: v
            };
  }
}

function wrap_fragment_status(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

var fragmentConverter = {"__root":{"status":{"u":"fragment_status"},"":{"f":""}}};

var fragmentConverterMap = {
  fragment_status: unwrap_fragment_status
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedForSalePropertyStatus_property",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientPropertyMlsStatus_property"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "status",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v1/*: any*/),
            "type": "ActiveProperty",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v1/*: any*/),
            "type": "ExpiredProperty",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v1/*: any*/),
            "type": "SoldProperty",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v1/*: any*/),
            "type": "UnderContractProperty",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "status"
    }
  ],
  "type": "ForSalePropertyV5",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.unwrap_fragment_status = unwrap_fragment_status;
exports.wrap_fragment_status = wrap_fragment_status;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
