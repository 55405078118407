// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var HtmlDisplayer = require("../common/Components/HtmlDisplayer.bs.js");
var MarkupHelpers = require("../../reason/common/MarkupHelpers/MarkupHelpers.bs.js");
var PropertyPrice = require("../common/Property/PropertyPrice.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PropertyLocationMap = require("../common/Property/PropertyLocationMap.bs.js");
var RentalPropertyLease = require("../common/Property/RentalPropertyLease.bs.js");
var PropertyImageGallery = require("../common/Components/PropertyImageGallery.bs.js");
var ClientPropertyLocation = require("../cap/Property/ClientPropertyLocation.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var SharedPropertySidecard = require("./SharedPropertySidecard.bs.js");
var RentalPropertyAttributes = require("../common/Property/RentalPropertyAttributes.bs.js");
var SharedRentalPropertyStatus = require("./SharedRentalPropertyStatus.bs.js");
var SharedRentalProperty_property_graphql = require("../../__generated__/SharedRentalProperty_property_graphql.bs.js");
var SharedRentalProperty_sharedProperty_graphql = require("../../__generated__/SharedRentalProperty_sharedProperty_graphql.bs.js");

var convertFragment = SharedRentalProperty_property_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SharedRentalProperty_property_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SharedRentalProperty_property_graphql.node, convertFragment);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = SharedRentalProperty_sharedProperty_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(SharedRentalProperty_sharedProperty_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SharedRentalProperty_sharedProperty_graphql.node, convertFragment$1);
}

var SharedPropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

var baseClasses = "border-t border-gray-200 ";

function SharedRentalProperty$Divider(props) {
  var __size = props.size;
  var size = __size !== undefined ? __size : "Base";
  var tmp;
  tmp = size === "Base" ? "my-8" : "my-12";
  return JsxRuntime.jsx("div", {
              className: baseClasses + tmp
            });
}

var Divider = {
  baseClasses: baseClasses,
  make: SharedRentalProperty$Divider
};

function SharedRentalProperty(props) {
  var property = RescriptRelay_Fragment.useFragment(SharedRentalProperty_property_graphql.node, convertFragment, props.property);
  var sharedProperty = RescriptRelay_Fragment.useFragment(SharedRentalProperty_sharedProperty_graphql.node, convertFragment$1, props.sharedProperty);
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(PropertyImageGallery.make, {
                                property: property.fragmentRefs
                              }),
                          JsxRuntime.jsxs(MarkupHelpers.Container.make, {
                                children: [
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsx(ClientPropertyLocation.ByPropertyLocation.make, {
                                                                propertyLocation: sharedProperty.propertyLocation.fragmentRefs
                                                              }),
                                                          JsxRuntime.jsx(PropertyPrice.make, {
                                                                property: property.fragmentRefs
                                                              }),
                                                          JsxRuntime.jsx(RentalPropertyAttributes.make, {
                                                                property: property.fragmentRefs
                                                              }),
                                                          JsxRuntime.jsx(RentalPropertyLease.make, {
                                                                property: property.fragmentRefs
                                                              }),
                                                          JsxRuntime.jsx(SharedRentalPropertyStatus.make, {
                                                                property: property.fragmentRefs
                                                              })
                                                        ],
                                                        className: "flex flex-col space-y-4"
                                                      }),
                                                  JsxRuntime.jsx(SharedRentalProperty$Divider, {}),
                                                  JsxRuntime.jsx("section", {
                                                        children: JsxRuntime.jsx(HtmlDisplayer.make, {
                                                              text: sharedProperty.propertyDescription,
                                                              proseStyle: "md"
                                                            })
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsx(SharedPropertySidecard.make, {
                                                            agent: sharedProperty.sender.fragmentRefs
                                                          }),
                                                      className: "max-w-sm"
                                                    }),
                                                className: "md:flex w-full justify-end hidden"
                                              })
                                        ],
                                        className: "grid gap-16 grid-cols-1 md:grid-cols-[2fr_minmax(280px,1fr)]"
                                      }),
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(SharedRentalProperty$Divider, {
                                                size: "Large"
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsxs("section", {
                                                      children: [
                                                        JsxRuntime.jsx("h2", {
                                                              children: "Location",
                                                              className: "text-2xl font-medium text-gray-800"
                                                            }),
                                                        JsxRuntime.jsx(PropertyLocationMap.FromPropertyLocation.make, {
                                                              propertyLocation: sharedProperty.propertyLocation.fragmentRefs
                                                            })
                                                      ],
                                                      className: "flex flex-col gap-4"
                                                    }),
                                                className: "flex flex-col gap-8"
                                              })
                                        ]
                                      })
                                ]
                              })
                        ],
                        className: "space-y-8 bg-white"
                      }))
            });
}

var Sidecard;

var make = SharedRentalProperty;

exports.PropertyFragment = PropertyFragment;
exports.SharedPropertyFragment = SharedPropertyFragment;
exports.Divider = Divider;
exports.Sidecard = Sidecard;
exports.make = make;
/* HtmlDisplayer Not a pure module */
