// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Labels = require("../../reason/clients/components/Labels.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../common/ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var ClientPropertyMlsStatus = require("../cap/Property/ClientPropertyMlsStatus.bs.js");
var SharedRentalPropertyStatus_property_graphql = require("../../__generated__/SharedRentalPropertyStatus_property_graphql.bs.js");

var convertFragment = SharedRentalPropertyStatus_property_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SharedRentalPropertyStatus_property_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SharedRentalPropertyStatus_property_graphql.node, convertFragment);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function SharedRentalPropertyStatus$WithoutErrorBoundary(props) {
  var property = RescriptRelay_Fragment.useFragment(SharedRentalPropertyStatus_property_graphql.node, convertFragment, props.property);
  var match = property.status;
  var variant = match.NAME;
  return JsxRuntime.jsx("div", {
              children: variant === "UnselectedUnionMember" || variant === "ActiveProperty" ? JsxRuntime.jsx(ClientPropertyMlsStatus.make, {
                      property: property.fragmentRefs
                    }) : (
                  variant === "ExpiredProperty" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            JsxRuntime.jsx(Labels.ExpiredStatusLabel.make, {}),
                            JsxRuntime.jsx(ClientPropertyMlsStatus.make, {
                                  property: property.fragmentRefs
                                })
                          ]
                        }) : JsxRuntime.jsx(Labels.RentedLabel.make, {})
                ),
              className: "flex gap-4"
            });
}

var WithoutErrorBoundary = {
  make: SharedRentalPropertyStatus$WithoutErrorBoundary
};

function SharedRentalPropertyStatus(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "property status",
              children: JsxRuntime.jsx(SharedRentalPropertyStatus$WithoutErrorBoundary, {
                    property: props.property
                  })
            });
}

var make = SharedRentalPropertyStatus;

exports.Fragment = Fragment;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* Labels Not a pure module */
